
import { useGetGameInfoDataByAppQuery, useAdminGameTypesQuery, useGetAppsQuery, useAdminSyncGameDataMutation } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";

const ROWS_PER_PAGE = 20

const context = {}

type gameInfoType = {
    ID: string
    Name: string
    ShortDescription: string
    LongDescription: string
    GameTypeID: number
    DisplayTimeInfo: string
    GameDuration: number
    GameplayConfigFile: string
    GameInfoCustomConfig: object
    GameOptionID: string
    Thumbnail: string
    Difficulty: string
    Tags: string[]
    AllowUser: string[]
    open: boolean
    textEdit: boolean
    edited: boolean
}
type gameTypesType = {
    ID: number
    Name: string
}

const useGameInfoHook = () => {
  const [adminSyncGameData] = useAdminSyncGameDataMutation()

      const [currentPage, setCurrentPage] = React.useState(0);
      const [totalPage, setTotalPage] = React.useState(0);
      const [gameInfo, setGameInfo] = React.useState([] as gameInfoType[]);
      const [currentApp, setCurrentApp] = React.useState(1);

      const [searchText, setSearchText] = React.useState("");
      const [currentGt, setCurrentGt] = React.useState(-1);
      const [tag, setTag] = React.useState("-1");



      const { data, loading, refetch } = useGetGameInfoDataByAppQuery({
        variables: {
          input: {
            appId: currentApp || 1,
          }
        },
          context
        });

      const { data: dataApp } = useGetAppsQuery({
        context
      });
      const {
        data: dataGT
      } = useAdminGameTypesQuery({
        variables: {
          input: {
            appId: Number(currentApp || 1),
          }
        },
      });
    const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);
    const [apps, setApps] = React.useState([] as gameInfoType[]);

  
    React.useMemo(() => {
      if (dataApp && dataApp.adminGameApps) {
        setApps(dataApp.adminGameApps.map((e: any) => {
              return {
                  ID: e.id,
                  Name: e.name,
              }
          }))
      }
    }, [dataApp]);


    const filterAll = (txt: string, t: string, gt: number, p: number) => {
      if (data && data?.getGameInfoDataByApp) {
        let dataFiltered = data?.getGameInfoDataByApp
        if (txt) {
          dataFiltered = dataFiltered?.filter((e: any) => e.Name.toLowerCase().includes(txt.toLowerCase()))
        }
        if (t != "-1") {
          dataFiltered = dataFiltered?.filter((e: any) => e?.Tags?.includes(t))
        }
        if (!isNaN(+gt) && +gt != -1) {
          dataFiltered = dataFiltered?.filter((e: any) => e.GameTypeID == +gt)
        }
        dataFiltered = dataFiltered?.map((e: any) => {  
          return {
            ID: e.ID,
            Name: e.Name,
            ShortDescription: e.ShortDescription,
            LongDescription: e.LongDescription,
            GameTypeID: e.GameTypeID,
            DisplayTimeInfo: e.DisplayTimeInfo,
            GameDuration: e.GameDuration,
            GameplayConfigFile: e.GameplayConfigFile,
            GameInfoCustomConfig: e.GameInfoCustomConfig,
            GameOptionID:  e.GameOptionID,
            Thumbnail: e.Thumbnail,
            Difficulty: e.Difficulty,
            Tags: e.Tags,
            AllowUser: e.AllowUser,
            open: false,
            textEdit: false,
            edited: false,
          }
        })

        const dataGi = dataFiltered.slice((currentPage) * 20, (currentPage + 1) * 20)
        setGameInfo(dataGi)
        const ttPage = Math.ceil(dataFiltered.length / ROWS_PER_PAGE)
        setTotalPage(ttPage) 
      } else {
        toast.error("Hmmm!!! Something went wrong, please refresh page!", { autoClose: 3000 });
      }
    }

    React.useMemo(() => {
      if (data && data.getGameInfoDataByApp) {
        filterAll(searchText, tag, currentGt, currentPage) 
      }
    }, [data]);

    React.useMemo(() => {
        if (dataGT && dataGT.adminGameTypes ) {
            setGameTypes(dataGT.adminGameTypes.sort((a: any, b: any) => a.id - b.id).map((e: any) => {
                return {
                    ID: e.id,
                    Name: e.name,
                }
            }))
        }
    }, [dataGT]);

    const columns = [
        {
            disablePadding: false,
            label: "ID",
            id: "ID",
            width: "15%"
        },
        {
            label: "Name",
            id: "Name",
            width: "25%"
        },
        {
            label: "GameTypeID",
            id: "GameTypeID",
            width: "10%"
        },
        {
            label: "ConfigFile",
            id: "ConfigFile",
            width: "15%"
        },
        {
            label: "GameOptionID",
            id: "GameOptionID",
            width: "15%"
        },
        {
            label: "Thumbnail",
            id: "Thumbnail",
            width: "15%"
        },
      ]


    const onChangeApp = (event: any) => {
      const {value} = event.target

      setCurrentApp(+value)
      setCurrentPage(0)
      setTag("-1")
      setSearchText("")
      refetch()
    }

    const handleSearch = (textInput: string) => {
      setSearchText(textInput)
      filterAll(textInput, tag, currentGt, currentPage) 
    }


    const filterType = (event: any) => {
      const {value} = event.target
      setCurrentGt(+value)
      filterAll(searchText, tag, +value, currentPage) 
    }

    const handleChangePage = (page: number) => {
      setCurrentPage(page)
      filterAll(searchText, tag, currentGt, page) 
    }


    const onRequestSync = async () => {
      await adminSyncGameData({
        variables: {
          input: {
            appId: currentApp,
          }
        },
      }).then((res)=>{
        toast.success("Sync Game Info Success", { autoClose: 3000 });
      }).catch((err)=>{
        //   errorHandle(err, formik)
        toast.error("Sync Game Info Error", { autoClose: 3000 });
      })
    }

    const filterTag = (event: any) => {
      const {value} = event.target
      setTag(value)
      filterAll(searchText, value, currentGt, currentPage) 
    }


    return {
      loading,
      gameInfo,
      columns,
      apps,
      currentApp,
      gameTypes,
      onChangeApp,
      handleSearch,
      handleChangePage,
      currentPage,
      totalPage,
      filterType,
      currentGt,
      tag,
      filterTag,
      onRequestSync,
    };
};

export default useGameInfoHook;
