
import { useHistory } from "react-router-dom";
import { useAddOrUpdateGameInfoMutation, useGetGameTypesDataQuery, useGetGameOptionsDataQuery, useGetAppsQuery } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { formSchema } from "./formSchemal";
import { CONSTANTS } from "consts";

import {
    api,
} from "services";

const context = {}

type gameInfoType = {
    ID: string
    Name: string
    ShortDescription: string
    LongDescription: string
    GameTypeID: number
    DisplayTimeInfo: string
    GameDuration: number
    GameplayConfigFile: string
    GameInfoCustomConfig: object
    GameOptionID: string
    Thumbnail: string
    Difficulty: string
    Tags: string[]
    AllowUser: string[]
    RestrictMachineType?: string
    open: boolean
    textEdit: boolean
    edited: boolean
}
type gameTypesType = {
    ID: number
    Name: string
}

const useGameInfoHook = () => {
    const history = useHistory();
    const [updateGameInfo] = useAddOrUpdateGameInfoMutation()
    const formik = useFormik({
        initialValues: {
            ID: "Game_",
            AppID: 1,
            Name: "",
            ShortDescription: "",
            LongDescription: "",
            GameTypeID: "0",
            DisplayTime: "∞",
            GameDuration: "-1",
            FileConfig: "",
            CustomConfig: {},
            GameOptions:   "",
            Thumbnail:  "",
            Difficulty:  {},
            Tags: [],
            AllowUser: ["Normal","Demo","Tester","Guest"],
            RestrictMachineType: "",
            Status: "NONE",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {},
  });

  const [loading, setLoading] = React.useState(false);

  const [thumbFile, setThumbFile] = React.useState({} as File);

    const { data: dataGT } = useGetGameTypesDataQuery({
        context
      });
    const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);

    React.useMemo(() => {
        if (dataGT && dataGT.getGameTypesData ) {
            setGameTypes(dataGT.getGameTypesData.map((e: any) => {
                return {
                    ID: e.id,
                    Name: e.name,
                }
            }))
        }
    }, [dataGT]);

    const { data: dataGO } = useGetGameOptionsDataQuery({
        context
      });
    const [gameOptions, setGameOptions] = React.useState([] as gameTypesType[]);

    React.useMemo(() => {
        if (dataGO && dataGO.getGameOptionsData ) {
            setGameOptions(dataGO.getGameOptionsData.map((e: any) => {
                return {
                    ID: e.id,
                    Name: e.id,
                }
            }))
        }
    }, [dataGO]);

    const { data: dataApp } = useGetAppsQuery({
      context
    });
  const [apps, setApps] = React.useState([] as gameTypesType[]);

  React.useMemo(() => {
      if (dataApp && dataApp.adminGameApps) {
        setApps(dataApp.adminGameApps.map((e: any) => {
              return {
                  ID: e.id,
                  Name: e.name,
              }
          }))
      }
  }, [dataApp]);

    const onChangeTags = (name: any, value: any) => {
        formik.setFieldValue("Tags", value.map((e: any) => e.value))
    }

    const onChangeAllowUser = (name: any, value: any) => {
        formik.setFieldValue("AllowUser", value.map((e: any) => e.value))
    }

    const onChangeApp = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("AppID", value)
    }

    const onChangeGameType = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("GameTypeID", value)
    }

    const onChangeGameOption = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("GameOptions", value)
    }

    const onChangeGameStatus = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("Status", value)
    }

    const onChangeCustomConfig = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("CustomConfig", jsObject, true);
      }
    }

    const onChangeDifficulty = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("Difficulty", jsObject, true);
      }
    }

    const handleSubmitForms = async () => {
      setLoading(true);
      if (thumbFile && Object.keys(thumbFile).length) {
        const uploadData = await api.gameThumbUploadAPI.uploadThumb(
          thumbFile
        )
      }
        if (formik.values.Name.trim() !== "") {
          if (
            formik.isValid
          ) {
            setLoading(true);
            updateGameInfo({
              variables: {
                input: [{
                  "ID": formik.values.ID,
                  "AppID": +formik.values.AppID,
                  "Name": formik.values.Name,
                  "ShortDescription": formik.values.ShortDescription,
                  "LongDescription": formik.values.LongDescription,
                  "GameTypeID": formik.values.GameTypeID.toString(),
                  "DisplayTime": formik.values.DisplayTime,
                  "FileConfig": formik.values.FileConfig,
                  "CustomConfig": formik.values.CustomConfig,
                  "GameOptions": formik.values.GameOptions,
                  "Thumbnail": thumbFile?.name || "",
                  "Difficulty": formik.values.Difficulty,
                  "Tag": formik.values.Tags,
                  "GameDuration": formik.values.GameDuration.toString(),
                  "AllowUser": formik.values.AllowUser.join(','),
                  "RestrictMachineType": formik.values?.RestrictMachineType,
                  "Status": formik.values.Status || "NONE",
                }]
                },
            }).then((res)=>{
              setLoading(false);
              toast.success("Create Game Info Success", { autoClose: 3000 });
              history.push(CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_INFO)
            }).catch((err)=>{
            //   errorHandle(err, formik)
              setLoading(false)
            }).finally(()=>{
              setLoading(false);
            });
          } else if (!formik.isValid) {
            setLoading(false);
            const el = document.getElementById("profileFormId");
            el?.scrollIntoView();
            return;
          } else {
            setLoading(false);
            alert("have error");
          }
        } else {
          setLoading(false);
        }
      }

    const changeImage = (acceptedFile: any, url: string) => {
      setThumbFile(acceptedFile)
      formik.setFieldValue("ThumbnailUrl", url, true);
    }

    return {
        loading,
        formik,
        gameTypes,
        gameOptions,
        apps,
        onChangeTags,
        onChangeAllowUser,
        handleSubmitForms,
        onChangeApp,
        onChangeGameType,
        onChangeGameOption,
        onChangeCustomConfig,
        onChangeDifficulty,
        changeImage,
        onChangeGameStatus,
    };
};

export default useGameInfoHook;
