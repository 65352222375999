import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    SelectAutoComplete,
} from "Components/base";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./GameInfoAdd.hook";
import { torem } from "styles/theme/toRem";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import style from "./AddPost.module.scss";
import { JsonEditor } from 'json-edit-react'
import {tags, allowUsers, statuses} from '../../consts'

export const GameInfoAdd = () => {
    const {
        formik,
        gameTypes,
        gameOptions,
        apps,
        onChangeTags,
        onChangeAllowUser,
        // setBack,
        // handleUploadIconGray,
        // handleUploadIcon,
        // conditionFormik,
        handleSubmitForms,
        onChangeApp,
        onChangeGameType,
        onChangeGameOption,
        loading,
        onChangeCustomConfig,
        onChangeDifficulty,
        // changeImage,
        onChangeGameStatus,
    } = useAddHook();

    const mapToSelected = (data: string[]) => {
      return data.map(g => {
          return {
              value: g,
              label: g,
              key: g
          }
      })
  }

    return (
      <div className={style["AddPostWrapper"]}>
        <Grid
          container
          spacing={torem(16)}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item>
            <div className="aviron-sub-title pointer" onClick={() => {}}>
              GameInfo Management &gt; Add Game
            </div>
            <div className="aviron-title" role="presentation">
              &nbsp; Add Game
            </div>
          </Grid>
          <Grid item className={"buttonGroup"}>
            <ButtonWithLoading
              variant="contained"
              loading={loading}
              type="submit"
              onClick={handleSubmitForms}
              startIcon={<SaveIcon />}
              style={{ minWidth: torem(120) }}
            >
              Create
            </ButtonWithLoading>
          </Grid>
        </Grid>
        <div className={style["addBody"]}>
          {/* {!lodash.isEmpty(formik.errors) && (
            <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
              <FormHelperText>Error in Profile Form</FormHelperText>
            </div>
          )} */}
            <form>
              <div className={style["profileContent"]}>
              <FormControl fullWidth className="form-aviron">
                {/* <ImageUpload
                  label="Game Thumbnail"
                  required={true}
                  //@ts-ignore
                  thumbValue={formik.values.ThumbnailUrl}
                  onChangeValue={formik.handleChange}
                  setFieldValue={formik.setFieldValue}
                  nameFieldForm="iconGray"
                  isHideUploadFile
                  defaultValue=""
                  multi={false}
                  changeImage={changeImage}
                /> */}
                </FormControl>
                    <FormControl fullWidth className="form-aviron">
                      <TextField
                        required
                        id="ID"
                        label="ID"
                        placeholder="Enter ID"
                        fullWidth
                        name="ID"
                        margin="normal"
                        variant="outlined"
                        error={formik.errors.ID ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ID}
                        helperText={
                          // @ts-ignore
                          formik.errors.ID && formik.touched.ID
                            ? formik.errors.ID
                            : null
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth className="form-aviron">
                      <TextField
                        required
                        id="Name"
                        label="Name"
                        placeholder="Enter Name"
                        fullWidth
                        name="Name"
                        margin="normal"
                        variant="outlined"
                        error={formik.errors.Name ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Name}
                        helperText={
                          // @ts-ignore
                          formik.errors.Name && formik.touched.Name
                            ? formik.errors.Name
                            : null
                        }
                      />
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="form-aviron"
                      style={{ marginTop: torem(16) }}
                    >
                      <InputLabel
                        // ref={inputLabel}
                        htmlFor="outlined-machine-type"
                        required
                      >
                        App
                      </InputLabel>
                      <Select
                            displayEmpty
                            name="AppID"
                            placeholder={"Select App"}
                            value={formik.values.AppID}
                            onChange={onChangeApp}
                            fullWidth
                            error={!!formik.errors.AppID}
                        >
                            {apps?.map((item: any, key: any) => {
                            return <MenuItem value={item.ID} key={item.ID}>{item.Name}</MenuItem>;
                            })}
                        </Select>
                      {/* <FormHelperText error={formik.errors.AppID ? true : false}>
                        {formik.errors.AppID && formik.touched.AppID
                          ? formik.errors.AppID
                          : null}
                      </FormHelperText> */}
                    </FormControl>
                    <FormControl
                        fullWidth
                        className="form-aviron"
                        style={{ marginTop: torem(16) }}
                    >
                        <InputLabel
                        // ref={inputLabel}
                        htmlFor="outlined-machine-type"
                        required
                        >
                        Game Type
                        </InputLabel>
                        <Select
                            displayEmpty
                            name="type"
                            placeholder={"Select a Type"}
                            value={formik.values.GameTypeID}
                            onChange={onChangeGameType}
                            fullWidth
                            error={!!formik.errors.GameTypeID}
                        >
                            {gameTypes?.map((item: any, key: any) => {
                            return <MenuItem value={item.ID} key={item.ID}>{item.Name}</MenuItem>;
                            })}
                        </Select>
                        {/* <FormHelperText error={formik.errors.GameTypeID ? true : false}>
                        {formik.errors.GameTypeID && formik.touched.GameTypeID
                            ? formik.errors.GameTypeID
                            : null}
                        </FormHelperText> */}
                    </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="ShortDescription"
                    label="Short Description"
                    placeholder="Enter Short Description"
                    fullWidth
                    name="ShortDescription"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={formik.values.ShortDescription}
                    error={
                      formik.errors.ShortDescription && formik.touched.ShortDescription
                        ? true
                        : false
                    }
                    // required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.ShortDescription}
                    helperText={
                      formik.errors.ShortDescription && formik.touched.ShortDescription
                        ? formik.errors.ShortDescription
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    id="LongDescription"
                    label="Long Description"
                    placeholder="Enter Long Description"
                    fullWidth
                    name="LongDescription"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={formik.values.LongDescription}
                    error={
                      formik.errors.LongDescription && formik.touched.LongDescription
                        ? true
                        : false
                    }
                    // required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.LongDescription}
                    helperText={
                      formik.errors.LongDescription && formik.touched.LongDescription
                        ? formik.errors.LongDescription
                        : null
                    }
                  />
                </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="DisplayTime"
                      label="Display Time"
                      placeholder="Enter Name"
                      fullWidth
                      name="DisplayTime"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.DisplayTime ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.DisplayTime}
                      helperText={
                        // @ts-ignore
                        formik.errors.DisplayTime && formik.touched.DisplayTime
                          ? formik.errors.DisplayTime
                          : null
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      id="FileConfig"
                      label="FileConfig"
                      placeholder="Enter"
                      fullWidth
                      name="FileConfig"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.FileConfig ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.FileConfig}
                      helperText={
                        // @ts-ignore
                        formik.errors.FileConfig && formik.touched.FileConfig
                          ? formik.errors.FileConfig
                          : null
                      }
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={"form-aviron"}
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel>Custom Config</InputLabel>
                      <JsonEditor
                        id="CustomConfig"
                        // colors={colorsEditorConf}
                        data={formik.values.CustomConfig}
                        // onUpdate={({newValue}) => onChangeCustomConfig(newValue)}
                        setData={onChangeCustomConfig}
                      />
                  </FormControl>
                <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                >
                    <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    >
                    Game Option
                    </InputLabel>
                    <Select
                        displayEmpty
                        name="type"
                        placeholder={"Select a Option"}
                        value={formik.values.GameOptions}
                        onChange={onChangeGameOption}
                        fullWidth
                        error={!!formik.errors.GameOptions}
                    >
                        {gameOptions?.map((item: any) => {
                        return <MenuItem value={item.ID} key={item.ID}>{item.Name}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error={formik.errors.GameOptions ? true : false}>
                    {formik.errors.GameOptions && formik.touched.GameOptions
                        ? formik.errors.GameOptions
                        : null}
                    </FormHelperText>
                </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="Thumbnail"
                      label="Thumbnail"
                      placeholder="Enter Thumbnail"
                      fullWidth
                      name="Thumbnail"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.Thumbnail ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Thumbnail}
                      helperText={
                        // @ts-ignore
                        formik.errors.Thumbnail && formik.touched.Thumbnail
                          ? formik.errors.Thumbnail
                          : null
                      }
                    />
                  </FormControl>
                <FormControl
                  fullWidth
                  className={"form-aviron"}
                  style={{ marginTop: torem(16) }}
                >
                  
                  <InputLabel>Difficulty</InputLabel>
                      <JsonEditor
                        id="CustomConfig"
                        // colors={colorsEditorConf}
                        data={formik.values.Difficulty}
                        // onUpdate={({newValue}) => onChangeDifficulty(newValue)}
                        setData={onChangeDifficulty}
                      />
                </FormControl>
                <FormControl
                  fullWidth
                  className={"form-aviron"}
                  style={{ marginTop: torem(16) }}
                >
                  <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    required
                    >
                    Select Tags
                    </InputLabel>
                  <SelectAutoComplete
                      fullWidth
                      name={'selector-tags'}
                      placeholder="Select Tags"
                      multiple
                      options={tags.map((item)=>{
                          return {
                              value: item,
                              label: item,
                              key: item
                          }
                      })}
                      inputProps={{ name: 'selector-tags' }}
                      onChange={onChangeTags}
                      value={mapToSelected(formik.values.Tags)}
                      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className={"form-aviron"}
                  style={{ marginTop: torem(16) }}
                >
                  <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    required
                    >
                    Select Allow Users
                    </InputLabel>
                  <SelectAutoComplete
                      fullWidth
                      name={'selector-allow-users'}
                      placeholder="Select Allow Users"
                      multiple
                      options={allowUsers.map((item)=>{
                          return {
                              value: item,
                              label: item,
                              key: item
                          }
                      })}
                      inputProps={{ name: 'selector-allow-users' }}
                      onChange={onChangeAllowUser}
                      value={mapToSelected(formik.values.AllowUser)}
                      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                  />
                </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="GameDuration"
                      label="GameDuration"
                      placeholder="Enter"
                      fullWidth
                      name="GameDuration"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.GameDuration ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.GameDuration}
                      helperText={
                        // @ts-ignore
                        formik.errors.GameDuration && formik.touched.GameDuration
                          ? formik.errors.GameDuration
                          : null
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="RestrictMachineType"
                      label="RestrictMachineType"
                      placeholder="Enter"
                      fullWidth
                      name="RestrictMachineType"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.RestrictMachineType ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.RestrictMachineType}
                      helperText={
                        // @ts-ignore
                        formik.errors.RestrictMachineType && formik.touched.RestrictMachineType
                          ? formik.errors.RestrictMachineType
                          : null
                      }
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                >
                    <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    >
                    Game Status
                    </InputLabel>
                    <Select
                        displayEmpty
                        name="type"
                        value={formik.values.Status}
                        onChange={onChangeGameStatus}
                        fullWidth
                        error={!!formik.errors.Status}
                    >
                        {statuses?.map((item: any) => {
                        return <MenuItem value={item} key={item}>{item}</MenuItem>;
                        })}
                    </Select>
                    <FormHelperText error={formik.errors.Status ? true : false}>
                    {formik.errors.Status && formik.touched.Status
                        ? formik.errors.Status
                        : null}
                    </FormHelperText>
                </FormControl>
              </div>
            </form>
        </div>
        {/* {loading ? <RingLoading /> : null} */}
      </div>
    );
};

